module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"3b5b90f9-4ca5-5973-827e-c3193465f8b5","name":"gatsby-remark-images","version":"3.1.42","pluginOptions":{"plugins":[],"maxWidth":700,"backgroundColor":"transparent","showCaptions":true},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":700,"backgroundColor":"transparent","showCaptions":true}},"gatsby-remark-copy-linked-files","gatsby-remark-embed-video",{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-autolink-headers","gatsby-remark-smartypants",{"resolve":"@weknow/gatsby-remark-twitter","options":{"align":"center"}},"gatsby-remark-external-links"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"backgroundColor":"transparent","showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Laerte","short_name":"Laerte","start_url":"/","background_color":"#001724","theme_color":"#001724","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
